import React from "react"

import { graphql } from "gatsby"

import Icon from "../components/ui/Icon"
import Image from "../components/ui/Image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const {
    subtitle,
    social: { github, linkedin },
  } = data.site.siteMetadata
  return (
    <Layout>
      <SEO
        title="Randall Lough - Software Engineer"
        description="Personal website for Randall Lough, a professional marketer turned software engineer."
        path="/"
        keywords={[
          "randy lough",
          "randall lough",
          "web developer",
          "software engineer",
          "marketer",
          "marketer turned software engineer",
          "programmer",
          "coder",
        ]}
      />
      <div
        className="flex items-center justify-center flex-row"
        style={{ height: "78vh" }}
      >
        <div className="text-center">
          <div>
            <Image
              {...data.emoji}
              className="mx-auto h-64"
              alt="me in emoji form"
            />
          </div>
          <div className="mb-5">
            <h1 className="text-5xl sm:text-6xl mb-0 uppercase leading-none">
              <span className="block sm:inline text-primary-500 font-black">
                Randall
              </span>
              <span className="block sm:inline text-gray-600 font-light -ml-px">
                Lough
              </span>
            </h1>
            <p className="text-gray-700 text-2xl mb-1 p-0">{subtitle}</p>
          </div>
          <ul className="flex justify-center">
            <li className="px-3 pt-3">
              <a
                className="text-gray-700 hover:text-primary-500 transition ease-in duration-150"
                href={github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  className="fill-current h-12"
                  icon="github"
                  variant="fab"
                  size="3x"
                />
              </a>
            </li>
            <li className="px-3 pt-3">
              <a
                className="text-gray-700 hover:text-primary-500 transition ease-in duration-150"
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  className="fill-current h-12"
                  icon="linkedin"
                  variant="fab"
                  size="3x"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        subtitle
        social {
          github
          linkedin
        }
      }
    }
    emoji: file(relativePath: { eq: "emoji.svg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
      publicURL
      extension
    }
  }
`
